import React from "react"

import Nav from "../components/Nav"
import Header from "../components/Header"
import HeroImage from "../components/HeroImage"
import Container from "../components/Container"
import Layout from "../components/Layout"
import styled from "styled-components"
import Section, { SectionTitle, SectionText } from "../components/Section"

const DateLine = styled.div`
  font-size: 24px;
  margin-top: 2rem;
  margin-bottom: 2rem;
`

const LargeDate = styled.span`
  font-weight: bold;
`

const Invitation = styled.p`
  text-align: center;
  font-size: 20px;
`

const HeroHeader = styled.div`
  position: absolute;
  width: 100%;
  top: 0px;
`

const StyledHeroImage = styled(HeroImage)`
  min-height: 700px;
  height: 100vh;
`

const IndexPage = () => (
  <Layout
    compact={false}
    renderHeader={() => (
      <>
        <StyledHeroImage />
        <HeroHeader>
          <Header compact={false} />
        </HeroHeader>
        <Nav />
      </>
    )}
  >
    <Section dark id="invitation">
      <Container pad>
        <Invitation>
          Please join us for our late wedding reception on
          <br />
          <DateLine>
            <LargeDate>May 28th, 2022 at 4:30pm</LargeDate>
          </DateLine>
          in Portland, Oregon at the Ecotrust Building
        </Invitation>
      </Container>
    </Section>
    <Section id="parking">
      <Container pad>
        <SectionTitle>Parking</SectionTitle>
        <p>
          Street parking around the venue has a two-hour limit, so if you are
          planning on driving, park in the lot to the East of the Ecotrust
          building. Payment in that lot requires the{" "}
          <a href="https://reeftechnology.com/parking-solutions/download-reef-mobile-app">
            REEF app
          </a>
          . Enter into the Ecotrust building from Irving Street.
        </p>
        <b>Accessible Parking</b>
        <p>
          There are a limited number of accessible parking spots in the Ecotrust
          parking lot (on the West side of the building). Some spots will be
          marked "Reserved for X", but those are available for us to use today.
          There is a door directly into the Irving Street Studio from the
          parking lot, to the right of the main building entrace.
        </p>
      </Container>
    </Section>
    <Section dark id="our-story">
      <Container pad>
        <SectionTitle>Our Story</SectionTitle>
        <SectionText>
          <p>
            Katrina and Rick met while working a summer job at Riverside Golf &
            Country Club in Portland, OR. While their golf games have declined,
            their relationship has grown.
          </p>
          <p>
            In March 2019, Rick surprised Katrina by proposing at the top of a
            sunrise hike in the Columbia River Gorge.
          </p>
          <p>
            After delaying their wedding from 2020 to 2021, they had a backyard
            wedding ceremony on May 1ˢᵗ, 2021. They're excited to finally get to
            celebrate their marriage with friends and family after waiting
            another year.
          </p>
        </SectionText>
      </Container>
    </Section>
  </Layout>
)

export default IndexPage
